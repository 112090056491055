import { GET_LABELS } from "../actions/types";

const initialState = {
  labels: [],
};

const labelReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LABELS:
      return { ...state, labels: payload };
    default:
      return state;
  }
};
export default labelReducer;
