import { GET_ALL_FAQ, FAQ_FAIL } from "../actions/types";
const initialState = {
  getfaq: null,
  loading: true,
};

const faqReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case GET_ALL_FAQ:
      return {
        ...state,
        getfaq: payload,
        loading: false,
      };
    case FAQ_FAIL:
      return {
        ...state,
        getfaq: null,
        loading: false,
      };
    default:
      return state;
  }
};
export default faqReducer;
