import { GET_TRANSACTION_HISTORY } from "../actions/types"
const initialState = {
    transactionData:{
        docs: [],
        totalDocs: 0,
        limit: 0,
        page: 0,
        totalPages: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null
    },
    transactionLoading: true,
}

const transactionHistoryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRANSACTION_HISTORY:
            return { ...state, transactionData: payload, transactionLoading: false};
        default:
            return state;
    }
};
export default transactionHistoryReducer;