import {
  GET_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  GET_SUB_CATEGORY,
} from "../actions/types";

const initialState = {
  categories: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  subCategory: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
};

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY:
      return {
        ...state,
        categories: payload,
        loading: false,
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        categories: {
          docs: state.categories.docs.filter(
            (category) => category._id !== payload
          ),
        },
        loading: false,
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: {
          docs: state.categories.docs.filter((category) =>
            category._id === payload._id ? (category = payload) : category
          ),
        },
        loading: false,
      };
    case GET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default categoryReducer;
