import { GET_LANGUAGES } from "../actions/types";

const initialState = {
  languages: [],
};

const languageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };

    default:
      return state;
  }
};
export default languageReducer;
