import {
  GET_TODOS,
  ADD_TODO,
  DELETE_TODO,
  UPDATE_TODO,
  GET_NEW_TODOS,
  SORT_TODO,
  SORT_TODO_CUSTOM,
} from "../actions/types";
import arrayMove from "array-move";
const initialState = {
  todos: [],
};

const todoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TODOS:
      return {
        ...state,
        todos: payload,
      };
    case GET_NEW_TODOS:
      return {
        ...state,
        todos: payload,
      };
    case ADD_TODO:
      return {
        ...state,
        todos: [payload, ...state.todos],
      };
    // case UPDATE_TODO:
    //   console.log(payload);
    //   return {
    //     ...state,
    //     todos: state.todos.filter((todo) =>
    //       todo._id === payload._id
    //         ? ((todo.title = payload.title), (todo.date = payload.date))
    //         : todo
    //     ),
    //   };
    case DELETE_TODO:
      return {
        ...state,
        todos: state.todos.filter((todo) => todo._id !== payload),
      };
    case SORT_TODO:
      return {
        ...state,
        todos: payload,
      };
    case SORT_TODO_CUSTOM:
      let todos = state.todos;
      todos = arrayMove(todos, payload.oldIndex, payload.newIndex);
      return {
        ...state,
        todos: todos,
      };
    case UPDATE_TODO:
      return {
        ...state,
        todos: state.todos.filter((todo) =>
          todo._id === payload._id ? (todo = payload) : todo
        ),
      };
    default:
      return state;
  }
};
export default todoReducer;
